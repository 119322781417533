import ApiService from '@/common/api.service'
import AlertService from '@/common/alert.service'
import VuhadoService from '@/common/custom-service/dna-source/VuhadoService'

const state = {
  testSourceData: {
    source: null,
    testTarget: null,
  },
  sourceObject: VuhadoService.sourceObject,
  targetButtons: [],
  distanceResult: [],
  singleResult: [],
}

const getters = {
  getTestSourceData(state) {
    return state.testSourceData
  },

  getSourceObject(state) {
    return state.sourceObject
  },

  getDistanceResult(state) {
    return state.distanceResult
  },

  getSingleResult(state) {
    return state.singleResult
  },

  getTargetButtons(state) {
    return state.targetButtons
  },
}

const mutations = {
  setTestSourceData(state, { key, value }) {
    state.testSourceData[key] = value;
    
    if (key === 'source') {
      const resolveSource = VuhadoService.textareaToArray(value, 'Source');
      if (resolveSource.isSuccess) {
        VuhadoService.onSetSource(resolveSource.result);
      }
    } else if (key === 'testTarget') {
      const resolveSource = VuhadoService.textareaToArray(value, 'Target');
      if (resolveSource.isSuccess) {
        VuhadoService.onSetTarget(resolveSource.result);

        /* eslint-disable */
        for (let i = 0; i < VuhadoService.sourceObject.targetNum; i++) {
          state.targetButtons.push({ index: i, title: VuhadoService.sourceObject.targetArray[i][0] });
        }
      }
    }
  },

  setSourceObject(state, { key, value }) {
    state.sourceObject[key] = value;
  },

  runAllDist(state) {
    /* eslint-disable */
    for (let i = 0; i < VuhadoService.sourceObject.targetNum; i++) {
      const result = VuhadoService.distances(i);
      state.distanceResult.unshift(result);
    }
  },

  dispatcher(state, targetId) {
    const result = VuhadoService.distances(targetId);
    state.distanceResult.unshift(result);
  },

  dispatchSingle(state, targetId) {
    const result = VuhadoService.singleFMC(targetId);
    state.singleResult.unshift(result);
  },

  runAllSingle(state) {
    /* eslint-disable */
    for (let i = 0; i < VuhadoService.sourceObject.targetNum; i++) {
      const result = VuhadoService.singleFMC(i);
      state.singleResult.unshift(result);
    }
  },
}

const actions = {
  fetchSourceDatas(ctx, queryParams) {
    return new Promise((resolve, reject) => {
      ApiService.post('SourceData/getall', queryParams, this)
        .then(response => resolve(response))
        .catch(error => reject(error))
    })
  },

  fetchSourceDatasByType(ctx, queryParams) {
    return new Promise((resolve, reject) => {
      ApiService.get(`SourceData/getall/${queryParams.type}`, {}, this)
        .then(response => resolve(response))
        .catch(error => reject(error))
    })
  },

  fetchSourceData(context, queryParams) {
    return new Promise((resolve, reject) => {
      ApiService.get(`SourceData/get/${queryParams.id}`, queryParams, this)
        .then(response => resolve(response))
        .catch(error => reject(error))
    })
  },

  saveSourceData(ctx, queryParams) {
    return new Promise((resolve, reject) => {
      ApiService.post('SourceData/save', queryParams, this)
        .then(response => resolve(response))
        .catch(error => reject(error))
    })
  },

  removeSourceData(context, queryParams) {
    return new Promise((resolve, reject) => {
      ApiService.get(`SourceData/delete/${queryParams.id}`, {}, this)
        .then(response => resolve(response))
        .catch(error => reject(error))
    })
  },
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
}
