import ApiService from '@/common/api.service'

const state = {
  profile: {},
}

const getters = {
  getProfile(state) {
    return state.profile
  },
}

const mutations = {
  setProfile(state, profile) {
    state.profile = profile
  },
}

const actions = {
  fetchProfile() {
    return new Promise((resolve, reject) => {
      ApiService.get('profile/get', {}, this)
        .then(response => resolve(response))
        .catch(error => reject(error))
    })
  },

  saveProfile(context, queryParams) {
    return new Promise((resolve, reject) => {
      ApiService.post('profile/save', queryParams, this)
        .then(response => resolve(response))
        .catch(error => reject(error))
    })
  },

  savePassword(context, queryParams) {
    return new Promise((resolve, reject) => {
      ApiService.post('profile/changepassword', queryParams, this)
        .then(response => resolve(response))
        .catch(error => reject(error))
    })
  },
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
}
