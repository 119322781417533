import ApiService from '@/common/api.service'
// import AlertService from '@/common/alert.service'
// import KullaniciYonetimService from '@/common/custom-service/management/KullaniciYonetimService'

const state = {
  
}

const getters = {
  
}

const mutations = {
  
}

const actions = {
  fetchUsers(ctx, queryParams) {
    return new Promise((resolve, reject) => {
      ApiService.post('UserPersonel/getall', queryParams, this)
        .then(response => resolve(response))
        .catch(error => reject(error))
    })
  },

  fetchUser(ctx, queryParams) {
    return new Promise((resolve, reject) => {
      ApiService.get(`UserPersonel/get/${queryParams.id}`, {}, this)
        .then(response => resolve(response))
        .catch(error => reject(error))
    })
  },

  saveUser(ctx, queryParams) {
    return new Promise((resolve, reject) => {
      ApiService.post('UserPersonel/save', queryParams, this)
        .then(response => resolve(response))
        .catch(error => reject(error))
    })
  },

  deleteUser(ctx, queryParams) {
    return new Promise((resolve, reject) => {
      ApiService.get(`UserPersonel/delete/${queryParams.id}`, {}, this)
        .then(response => resolve(response))
        .catch(error => reject(error))
    })
  },
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
}
