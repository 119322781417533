import ApiService from '@/common/api.service'

/* eslint-disable */
const getDefaultState = () => {
  return {
  }
}

const state = getDefaultState()

const getters = {
}

const mutations = {
}

const actions = {
  fetchAll(ctx, queryParams) {
    return new Promise((resolve, reject) => {
      ApiService.post('BusinessPartner/getall', queryParams, this)
        .then(response => resolve(response))
        .catch(error => reject(error))
    })
  },
  
  fetch(context, queryParams) {
    return new Promise((resolve, reject) => {
      ApiService.get(`BusinessPartner/${queryParams.id}`, queryParams, this)
        .then(response => resolve(response))
        .catch(error => reject(error))
    })
  },

  save(ctx, queryParams) {
    return new Promise((resolve, reject) => {
      ApiService.post('BusinessPartner', queryParams, this)
        .then(response => resolve(response))
        .catch(error => reject(error))
    })
  },

  remove(context, queryParams) {
    return new Promise((resolve, reject) => {
      ApiService.delete(`BusinessPartner/${queryParams.id}`, {}, this)
        .then(response => resolve(response))
        .catch(error => reject(error))
    })
  },
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
}
