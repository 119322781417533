import { Role } from '@/auth/utils'

export default [
  {
    path: '/management/home',
    name: 'manage-home',
    component: () => import('@/views/management/Home.vue'),
    meta: {
      authorize: [Role.Admin],
    },
  },
  {
    path: '/management/homebase',
    name: 'manage-homebase',
    component: () => import('@/views/management/HomeBasic.vue'),
    meta: {
      authorize: [Role.Admin, Role.TraitsAndHealth],
    },
  },

  // Kullanıcı Yönetim
  {
    path: '/management/user',
    name: 'manage-user',
    component: () => import('@/views/management/user-manage/UserList.vue'),
    meta: {
      breadcrumb: [
        {
          text: 'Users',
        },
      ],
      authorize: [Role.Admin],
    },
  },
  {
    path: '/management/user/:id',
    name: 'manage-user-save',
    component: () => import('@/views/management/user-manage/user-manage-detail/UserSaveDetail.vue'),
    meta: {
      breadcrumb: [
        {
          text: 'User',
          to: '/management/user',
        },
        {
          text: 'Save',
        },
      ],
      authorize: [Role.Admin],
    },
  },

  {
    path: '/management/user-personel',
    name: 'manage-user-personel',
    component: () => import('@/views/management/user-personel-manage/UserPersonelList.vue'),
    meta: {
      breadcrumb: [
        {
          text: 'Users',
        },
      ],
      authorize: [Role.Admin],
    },
  },
  {
    path: '/management/user-personel/:id',
    name: 'manage-user-personel-save',
    component: () => import('@/views/management/user-personel-manage/UserPersonelSave.vue'),
    meta: {
      breadcrumb: [
        {
          text: 'User Personel',
          to: '/management/user-personel',
        },
        {
          text: 'Save',
        },
      ],
      authorize: [Role.Admin],
    },
  },

  // Ticket
  {
    path: '/management/ticket-open',
    name: 'manage-ticket-open',
    component: () => import('@/views/management/others/ticket-manage/OpenTicketList.vue'),
    meta: {
      breadcrumb: [
        {
          text: 'Open Tickets',
        },
      ],
      authorize: [Role.Admin, Role.TraitsAndHealth],
    },
  },
  {
    path: '/management/ticket-closed',
    name: 'manage-ticket-closed',
    component: () => import('@/views/management/others/ticket-manage/ClosedTicketList.vue'),
    meta: {
      breadcrumb: [
        {
          text: 'Closed Tickets',
        },
      ],
      authorize: [Role.Admin, Role.TraitsAndHealth],
    },
  },
  {
    path: '/management/ticket-details/:id',
    name: 'manage-ticket-detail',
    component: () => import('@/views/management/others/ticket-manage/details/ManageTicketDetail.vue'),
    meta: {
      breadcrumb: [
        {
          text: 'Ticket detail',
        },
      ],
      authorize: [Role.Admin, Role.TraitsAndHealth],
    },
  },

  // Reports
  {
    path: '/management/report/orders',
    name: 'manage-report-order',
    component: () => import('@/views/management/reports/order-reports/OrderReportList.vue'),
    meta: {
      breadcrumb: [
        {
          text: 'Order Reports',
        },
      ],
      authorize: [Role.Admin],
    },
  },

  // Product 
  {
    path: '/management/product',
    name: 'manage-product',
    component: () => import('@/views/management/product-manage/ProductList.vue'),
    meta: {
      breadcrumb: [
        {
          text: 'Products',
        },
      ],
      authorize: [Role.Admin],
    },
  },
  {
    path: '/management/product/:id',
    name: 'manage-product-save',
    component: () => import('@/views/management/product-manage/product-details/ProductDetail.vue'),
    meta: {
      breadcrumb: [
        {
          text: 'Products',
          to: '/management/product',
        },
        {
          text: 'Save',
        },
      ],
      authorize: [Role.Admin],
    },
  },

  // Order Promotion
  {
    path: '/management/order-promotion',
    name: 'manage-order-promotion',
    component: () => import('@/views/management/order-promotion-manage/OrderPromotionList.vue'),
    meta: {
      breadcrumb: [
        {
          text: 'Order Promotions',
        },
      ],
      authorize: [Role.Admin],
    },
  },
  {
    path: '/management/order-promotion-archived',
    name: 'manage-order-promotion-archived',
    component: () => import('@/views/management/order-promotion-manage/ArchivedOrderPromotionList.vue'),
    meta: {
      breadcrumb: [
        {
          text: 'Order Promotions',
        },
      ],
      authorize: [Role.Admin],
    },
  },
  {
    path: '/management/order-promotion/:id',
    name: 'manage-order-promotion-save',
    component: () => import('@/views/management/order-promotion-manage/detail/OrderPromotionSave.vue'),
    meta: {
      breadcrumb: [
        {
          text: 'Order Promotions',
          to: '/management/order-promotion',
        },
        {
          text: 'Save',
        },
      ],
      authorize: [Role.Admin],
    },
  },

  // Preset Ethnicity
  {
    path: '/management/preset-ethnicity',
    name: 'manage-preset-ethnicity',
    component: () => import('@/views/management/definations/preset-ethnicity/PresetEthnicityList.vue'),
    meta: {
      breadcrumb: [
        {
          text: 'Preset Ethnicity',
        },
      ],
      authorize: [Role.Admin],
    },
  },
  {
    path: '/management/preset-ethnicity/:id',
    name: 'manage-preset-ethnicity-save',
    component: () => import('@/views/management/definations/preset-ethnicity/preset-ethnicity-detail/PresetEthnicitySave.vue'),
    meta: {
      breadcrumb: [
        {
          text: 'Preset Ethnicity Save',
          to: '/management/preset-ethnicity',
        },
        {
          text: 'Save',
        },
      ],
      authorize: [Role.Admin],
    },
  },

  // Preset Region Grup
  {
    path: '/management/preset-region-grup',
    name: 'manage-preset-region-grup',
    component: () => import('@/views/management/definations/preset-region-grup/PresetRegionGrupList.vue'),
    meta: {
      breadcrumb: [
        {
          text: 'Regions',
        },
      ],
      authorize: [Role.Admin],
    },
  },
  {
    path: '/management/preset-region-grup/:id',
    name: 'manage-preset-region-grup-save',
    component: () => import('@/views/management/definations/preset-region-grup/preset-region-grup-detail/PresetRegionGrupSave.vue'),
    meta: {
      breadcrumb: [
        {
          text: 'Preset Region',
          to: '/management/preset-region-grup',
        },
        {
          text: 'Save',
        },
      ],
      authorize: [Role.Admin],
    },
  },

  {
    path: '/management/preset-region-pc-grup',
    name: 'manage-pca-region-grup',
    component: () => import('@/views/management/definations/preset-region-grup/PresetRegionPcaGrupList.vue'),
    meta: {
      breadcrumb: [
        {
          text: 'Regions',
        },
      ],
      authorize: [Role.Admin],
    },
  },
  {
    path: '/management/preset-region-pca-grup/:id',
    name: 'manage-pca-region-grup-save',
    component: () => import('@/views/management/definations/preset-region-grup/preset-region-grup-detail/PresetRegionPcaGrupSave.vue'),
    meta: {
      breadcrumb: [
        {
          text: 'Preset Region PCA Grup',
          to: '/management/preset-region-pca-grup',
        },
        {
          text: 'Save',
        },
      ],
      authorize: [Role.Admin],
    },
  },

  // Preset Region
  {
    path: '/management/preset-region',
    name: 'manage-preset-region',
    component: () => import('@/views/management/definations/preset-region/PresetRegionList.vue'),
    meta: {
      breadcrumb: [
        {
          text: 'Preset Region',
        },
      ],
      authorize: [Role.Admin],
    },
  },
  {
    path: '/management/pca-region',
    name: 'manage-pca-region',
    component: () => import('@/views/management/definations/preset-region/PcaRegionList.vue'),
    meta: {
      breadcrumb: [
        {
          text: 'Pca Region',
        },
      ],
      authorize: [Role.Admin],
    },
  },
  
  {
    path: '/management/preset-region/:id',
    name: 'manage-preset-region-save',
    component: () => import('@/views/management/definations/preset-region/preset-region-detail/PresetRegionDetail.vue'),
    meta: {
      breadcrumb: [
        {
          text: 'Preset Region',
          to: '/management/preset-region',
        },
        {
          text: 'Save',
        },
      ],
      authorize: [Role.Admin],
    },
  },
  {
    path: '/management/pca-region/:id',
    name: 'manage-pca-region-save',
    component: () => import('@/views/management/definations/preset-region/pca-region-detail/PcaRegionDetail.vue'),
    meta: {
      breadcrumb: [
        {
          text: 'Pca Region',
          to: '/management/pca-region',
        },
        {
          text: 'Save',
        },
      ],
      authorize: [Role.Admin],
    },
  },

  // Preset Ancient Period
  {
    path: '/management/preset-ancient-period',
    name: 'manage-preset-ancient-period',
    component: () => import('@/views/management/definations/preset-ancient-period/PresetAncientPeriodList.vue'),
    meta: {
      breadcrumb: [
        {
          text: 'Preset Ancient Period',
        },
      ],
      authorize: [Role.Admin],
    },
  },
  {
    path: '/management/preset-ancient-period/:id',
    name: 'manage-preset-ancient-period-save',
    component: () => import('@/views/management/definations/preset-ancient-period/preset-ancient-period-detail/PresetAncientPeriodSave.vue'),
    meta: {
      breadcrumb: [
        {
          text: 'Preset Ancient Period',
          to: '/management/preset-ancient-period',
        },
        {
          text: 'Save',
        },
      ],
      authorize: [Role.Admin],
    },
  },

  // Preset MixedMode Period
  {
    path: '/management/preset-ancient-mixedmode-period',
    name: 'manage-preset-ancient-mixedmode-period',
    component: () => import('@/views/management/definations/preset-mixedmode-period/ancient-mixedmode/PresetMixedModePeriodList.vue'),
    meta: {
      breadcrumb: [
        {
          text: 'Preset Ancient Period',
        },
      ],
      authorize: [Role.Admin],
    },
  },
  {
    path: '/management/preset-ancient-mixedmode-period/:id',
    name: 'manage-preset-ancient-mixedmode-period-save',
    component: () => import('@/views/management/definations/preset-mixedmode-period/ancient-mixedmode/preset-mixedmode-period-detail/PresetMixedModePeriodSave.vue'),
    meta: {
      breadcrumb: [
        {
          text: 'Preset Mixed Mode Period',
          to: '/management/preset-ancient-mixedmode-period',
        },
        {
          text: 'Save',
        },
      ],
      authorize: [Role.Admin],
    },
  },

  {
    path: '/management/preset-modern-mixedmode-period',
    name: 'manage-preset-modern-mixedmode-period',
    component: () => import('@/views/management/definations/preset-mixedmode-period/modern-mixedmode/PresetMixedModePeriodList.vue'),
    meta: {
      breadcrumb: [
        {
          text: 'Preset Modern Period',
        },
      ],
      authorize: [Role.Admin],
    },
  },
  {
    path: '/management/preset-modern-mixedmode-period/:id',
    name: 'manage-preset-modern-mixedmode-period-save',
    component: () => import('@/views/management/definations/preset-mixedmode-period/modern-mixedmode/preset-mixedmode-period-detail/PresetMixedModePeriodSave.vue'),
    meta: {
      breadcrumb: [
        {
          text: 'Preset Mixed Mode Period',
          to: '/management/preset-modern-mixedmode-period',
        },
        {
          text: 'Save',
        },
      ],
      authorize: [Role.Admin],
    },
  },

  // Source Data
  {
    path: '/management/source-data',
    name: 'manage-source-data',
    component: () => import('@/views/management/definations/source-data/SourceDataList.vue'),
    meta: {
      breadcrumb: [
        {
          text: 'Source Data',
        },
      ],
      authorize: [Role.Admin],
    },
  },
  {
    path: '/management/source-data/:id',
    name: 'manage-source-data-save',
    component: () => import('@/views/management/definations/source-data/source-data-detail/SourceDataDetail.vue'),
    meta: {
      breadcrumb: [
        {
          text: 'Source Data',
          to: '/management/source-data',
        },
        {
          text: 'Save',
        },
      ],
      authorize: [Role.Admin],
    },
  },

  // Map Data
  {
    path: '/management/preset-map',
    name: 'manage-preset-map',
    component: () => import('@/views/management/definations/preset-map/PresetMapList.vue'),
    meta: {
      breadcrumb: [
        {
          text: 'Map definition',
        },
      ],
      authorize: [Role.Admin],
    },
  },
  {
    path: '/management/preset-map/:id',
    name: 'manage-preset-map-save',
    component: () => import('@/views/management/definations/preset-map/preset-map-detail/PresetMapDetail.vue'),
    meta: {
      breadcrumb: [
        {
          text: 'Map definition',
          to: '/management/preset-map',
        },
        {
          text: 'Save',
        },
      ],
      authorize: [Role.Admin],
    },
  },

  // Farmers HGs Data
  {
    path: '/management/preset-farmsers-hgs',
    name: 'manage-preset-farmsers-hgs',
    component: () => import('@/views/management/definations/farmers-hgs/PresetFarmersHgsList.vue'),
    meta: {
      breadcrumb: [
        {
          text: 'Farmer & HGs definition',
        },
      ],
      authorize: [Role.Admin],
    },
  },
  {
    path: '/management/preset-farmsers-hgs/:id',
    name: 'manage-preset-farmsers-hgs-save',
    component: () => import('@/views/management/definations/farmers-hgs/preset-farmer-hgs-detail/PresetFarmserHgsDetail.vue'),
    meta: {
      breadcrumb: [
        {
          text: 'Farmer & HGs definition',
          to: '/management/preset-farmsers-hgs',
        },
        {
          text: 'Save',
        },
      ],
      authorize: [Role.Admin],
    },
  },

  // Ancient Prox Data
  {
    path: '/management/ancient-prox',
    name: 'manage-ancient-prox',
    component: () => import('@/views/management/definations/ancient-prox/AncientProxList.vue'),
    meta: {
      breadcrumb: [
        {
          text: 'Ancient Proximity',
        },
      ],
      authorize: [Role.Admin],
    },
  },
  {
    path: '/management/ancient-prox/:id',
    name: 'manage-ancient-prox-save',
    component: () => import('@/views/management/definations/ancient-prox/ancient-prox-detail/AncientProxDetail.vue'),
    meta: {
      breadcrumb: [
        {
          text: 'Ancient Proximity',
          to: '/management/ancient-prox',
        },
        {
          text: 'Save',
        },
      ],
      authorize: [Role.Admin],
    },
  },

  // Preset Individual Ancient
  {
    path: '/management/ancient-individual',
    name: 'manage-ancient-individual',
    component: () => import('@/views/management/definations/preset-individual/PresetIndividualList.vue'),
    meta: {
      breadcrumb: [
        {
          text: 'Ancient (individual)',
        },
      ],
      authorize: [Role.Admin],
    },
  },
  {
    path: '/management/ancient-individual/:id',
    name: 'manage-ancient-individual-save',
    component: () => import('@/views/management/definations/preset-individual/preset-individual-detail/PresetIndividualSave.vue'),
    meta: {
      breadcrumb: [
        {
          text: 'Ancient (individual)',
          to: '/management/ancient-individual',
        },
        {
          text: 'Save',
        },
      ],
      authorize: [Role.Admin],
    },
  },

  // Data Update
  {
    path: '/management/data-update',
    name: 'manage-data-update',
    component: () => import('@/views/management/others/data-update/DataUpdateList.vue'),
    meta: {
      breadcrumb: [
        {
          text: 'Data Update',
        },
      ],
      authorize: [Role.Admin],
    },
  },
  {
    path: '/management/data-update/:id',
    name: 'manage-data-update-save',
    component: () => import('@/views/management/others/data-update/data-update-detail/DataUpdateSave.vue'),
    meta: {
      breadcrumb: [
        {
          text: 'Data Update',
          to: '/management/manage-data-update',
        },
        {
          text: 'Save',
        },
      ],
      authorize: [Role.Admin],
    },
  },

  // version-control
  {
    path: '/management/version-control',
    name: 'manage-version-control',
    component: () => import('@/views/management/others/version-control/VersionControlForm.vue'),
    meta: {
      breadcrumb: [
        {
          text: 'Versions',
        },
      ],
      authorize: [Role.Admin],
    },
  },

  // Business Partner Link
  {
    path: '/management/business-partner-link',
    name: 'manage-business-partner-link',
    component: () => import('@/views/business-partner-manage/business-partner-link/BusinessPartnerLinkList.vue'),
    meta: {
      breadcrumb: [
        {
          text: 'Business Partner Links',
        },
      ],
      authorize: [Role.Admin],
    },
  },
  {
    path: '/management/business-partner-file',
    name: 'manage-business-partner-file',
    component: () => import('@/views/business-partner-manage/business-partner-link/BusinessPartnerLinkUploadFile.vue'),
    meta: {
      breadcrumb: [
        {
          text: 'Business Partner Links',
        },
      ],
      authorize: [Role.Admin],
    },
  },
]
