import ApiService from '@/common/api.service'
/* eslint-disable */
const getDefaultState = () => {
  return {
  }
}

const state = getDefaultState()

const getters = {
}

const mutations = {
}

const actions = {
  fetchPayments(ctx, queryParams) {
    return new Promise((resolve, reject) => {
      ApiService.post('payment/getall', queryParams, this)
        .then(response => resolve(response))
        .catch(error => reject(error))
    })
  },
  fetchPayment(ctx, queryParams) {
    return new Promise((resolve, reject) => {
      ApiService.get(`payment/get/${queryParams.id}`, {}, this)
        .then(response => resolve(response))
        .catch(error => reject(error))
    });
  },

  savePayment(ctx, queryParams) {
    return new Promise((resolve, reject) => {
      ApiService.post('payment/save', queryParams, this)
        .then(response => resolve(response))
        .catch(error => reject(error))
    })
  },
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
}
