import ApiService from '@/common/api.service'

import { getField, updateField } from 'vuex-map-fields';

/* eslint-disable */
const getDefaultState = () => {
  return {
    productForm: {
      id: 0,
      isActive: true,
      code: null,
      name: null,
      displayName : null,
      unitPrice: 0,
      currencyUnit: '€',
      disCountedPrice: 0,
      taxRate: 0,
      description1: null,
      description2: null,
      description3: null,
      description4: null,
      pictureUrl: null,
      productType: null,
      rowDataUpload: 1,
    },
  }
}

const state = getDefaultState()

const getters = {
  getProductField(state) {
    return getField(state.productForm);
  },
  getProductForm(state) {
    return state.productForm;
  },
}

const mutations = {
  updateProductField(state, field) {
    updateField(state.productForm, field);
  },
  setProductField(state, data) {
    state.productForm = data;
  },
  resetProductField(state) {
    Object.assign(state, getDefaultState())
  },
}

const actions = {
  fetchProducts(ctx, queryParams) {
    return new Promise((resolve, reject) => {
      ApiService.post('Product/getall', queryParams, this)
        .then(response => resolve(response))
        .catch(error => reject(error))
    })
  },

  fetchProduct(context, queryParams) {
    return new Promise((resolve, reject) => {
      ApiService.get(`Product/get/${queryParams.id}`, queryParams, this)
        .then(response => resolve(response))
        .catch(error => reject(error))
    })
  },

  saveProduct(ctx, queryParams) {
    return new Promise((resolve, reject) => {
      ApiService.post('Product/save', queryParams, this)
        .then(response => resolve(response))
        .catch(error => reject(error))
    })
  },

  removeProduct(context, queryParams) {
    return new Promise((resolve, reject) => {
      ApiService.get(`Product/remove/${queryParams.id}`, {}, this)
        .then(response => resolve(response))
        .catch(error => reject(error))
    })
  },

  fetchProductGeoDetails(ctx, queryParams) {
    return new Promise((resolve, reject) => {
      ApiService.get(`ProductGeoDetails/getall/${ queryParams.id }`, {}, this)
        .then(response => resolve(response))
        .catch(error => reject(error))
    })
  },

  saveProductGeoDetail(ctx, queryParams) {
    return new Promise((resolve, reject) => {
      ApiService.post('ProductGeoDetails/save', queryParams, this)
        .then(response => resolve(response))
        .catch(error => reject(error))
    })
  },

  removeProductGeoDetail(context, queryParams) {
    return new Promise((resolve, reject) => {
      ApiService.get(`ProductGeoDetails/remove/${queryParams.id}`, {}, this)
        .then(response => resolve(response))
        .catch(error => reject(error))
    })
  },

  fetchProductPrices(ctx, queryParams) {
    return new Promise((resolve, reject) => {
      ApiService.get(`ProductPrice/getall/${ queryParams.id }`, {}, this)
        .then(response => resolve(response))
        .catch(error => reject(error))
    })
  },

  saveProductPrice(ctx, queryParams) {
    return new Promise((resolve, reject) => {
      ApiService.post('ProductPrice/save', queryParams, this)
        .then(response => resolve(response))
        .catch(error => reject(error))
    })
  },

  removeProductPrice(context, queryParams) {
    return new Promise((resolve, reject) => {
      ApiService.get(`ProductPrice/remove/${queryParams.id}`, {}, this)
        .then(response => resolve(response))
        .catch(error => reject(error))
    })
  },
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
}
