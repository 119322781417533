import { Role } from '@/auth/utils'

export default [
  // Order Management
  {
    path: '/management/order',
    name: 'manage-order',
    component: () => import('@/views/management/order-manage/g25-orders/OrdersList.vue'),
    meta: {
      breadcrumb: [
        {
          text: 'Orders',
        },
      ],
      authorize: [Role.Admin],
    },
  },
  {
    path: '/management/order/pending',
    name: 'manage-order-pending',
    component: () => import('@/views/management/order-manage/g25-orders/OrdersListPending.vue'),
    meta: {
      breadcrumb: [
        {
          text: 'Archived Orders',
        },
      ],
      authorize: [Role.Admin],
    },
  },
  {
    path: '/management/order/archived',
    name: 'manage-order-archived',
    component: () => import('@/views/management/order-manage/g25-orders/OrdersListArchived.vue'),
    meta: {
      breadcrumb: [
        {
          text: 'Archived Orders',
        },
      ],
      authorize: [Role.Admin],
    },
  },
  {
    path: '/management/order/save/:id',
    name: 'manage-order-save',
    component: () => import('@/views/management/order-manage/g25-orders/detail/OrderDetail.vue'),
    meta: {
      breadcrumb: [
        {
          text: 'Orders',
          to: '/management/order',
        },
        {
          text: 'Save',
        },
      ],
      authorize: [Role.Admin],
    },
  },

  // Order Trait
  {
    path: '/management/order/trait',
    name: 'manage-order-trait',
    component: () => import('@/views/management/order-manage/trait-orders/OrdersList.vue'),
    meta: {
      breadcrumb: [
        {
          text: 'Orders',
        },
      ],
      authorize: [Role.Admin],
    },
  },
  {
    path: '/management/order/trait/pending',
    name: 'manage-order-trait-pending',
    component: () => import('@/views/management/order-manage/trait-orders/OrdersListPending.vue'),
    meta: {
      breadcrumb: [
        {
          text: 'Archived Orders',
        },
      ],
      authorize: [Role.Admin],
    },
  },
  {
    path: '/management/order/trait/archived',
    name: 'manage-order-trait-archived',
    component: () => import('@/views/management/order-manage/trait-orders/OrdersListArchived.vue'),
    meta: {
      breadcrumb: [
        {
          text: 'Archived Orders',
        },
      ],
      authorize: [Role.Admin],
    },
  },
  {
    path: '/management/order/trait/save/:id',
    name: 'manage-order-trait-save',
    component: () => import('@/views/management/order-manage/trait-orders/detail/OrderDetail.vue'),
    meta: {
      breadcrumb: [
        {
          text: 'Orders',
          to: '/management/order/trait',
        },
        {
          text: 'Save',
        },
      ],
      authorize: [Role.Admin],
    },
  },

  // Order create
  {
    path: '/management/order/create',
    name: 'manage-order-create',
    component: () => import('@/views/management/order-manage/orders/create/CreateNewOrder.vue'),
    meta: {
      breadcrumb: [
        {
          text: 'Orders',
          to: '/management/order',
        },
        {
          text: 'Create',
        },
      ],
      authorize: [Role.Admin],
    },
  },

  // Payment Management
  {
    path: '/management/payment',
    name: 'manage-payment',
    component: () => import('@/views/management/payment-manage/PaymentList.vue'),
    meta: {
      breadcrumb: [
        {
          text: 'Payment',
        },
      ],
      authorize: [Role.Admin],
    },
  },

  // Logs
  {
    path: '/management/logs/order',
    name: 'manage-order-logs',
    component: () => import('@/views/management/logs-manage/orders/OrderLogsList.vue'),
    meta: {
      breadcrumb: [
        {
          text: 'Order Logs',
        },
      ],
      authorize: [Role.Admin],
    },
  },
  {
    path: '/management/logs/login',
    name: 'manage-login-logs',
    component: () => import('@/views/management/logs-manage/login/LoginLogsList.vue'),
    meta: {
      breadcrumb: [
        {
          text: 'Order Logs',
        },
      ],
      authorize: [Role.Admin],
    },
  },
]
