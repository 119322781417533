import Vue from 'vue'
import FeatherIcon from '@core/components/feather-icon/FeatherIcon.vue'
import SpinnerLoader from '@/components/SpinnerLoader.vue'
import CrudButton from '@/components/CrudButton.vue'
import vSelect from 'vue-select'

Vue.component('v-select', vSelect)
Vue.component(FeatherIcon.name, FeatherIcon)
Vue.component(SpinnerLoader.name, SpinnerLoader)
Vue.component(CrudButton.name, CrudButton)
