import ApiService from '@/common/api.service'

/* eslint-disable */
const getDefaultState = () => {
  return {
    presetRegionList: [],
  }
}

const state = getDefaultState()

const getters = {
}

const mutations = {
  setPresetRegionList(state, list) {
    state.presetRegionList = list;
  },
}

const actions = {
  fetchPresetRegionList(ctx, queryParams) {
    return ApiService.get('PresetRegion/getall', queryParams, this)
    .then(response => {
      if (response) {
        ctx.commit('setPresetRegionList', response.result);
      }
    });
  },

  fetchPresetRegions(ctx, queryParams) {
    return new Promise((resolve, reject) => {
      ApiService.post('PresetRegion/getall', queryParams, this)
        .then(response => resolve(response))
        .catch(error => reject(error))
    })
  },

  fetchPcaRegions(ctx, queryParams) {
    return new Promise((resolve, reject) => {
      ApiService.post('PresetRegion/getall/pca', queryParams, this)
        .then(response => resolve(response))
        .catch(error => reject(error))
    })
  },

  fetchPresetRegion(context, queryParams) {
    return new Promise((resolve, reject) => {
      ApiService.get(`PresetRegion/get/${queryParams.id}`, queryParams, this)
        .then(response => resolve(response))
        .catch(error => reject(error))
    })
  },

  savePresetRegion(ctx, queryParams) {
    return new Promise((resolve, reject) => {
      ApiService.post('PresetRegion', queryParams, this)
        .then(response => resolve(response))
        .catch(error => reject(error))
    })
  },

  removePresetRegion(context, queryParams) {
    return new Promise((resolve, reject) => {
      ApiService.delete(`PresetRegion/${queryParams.id}`, {}, this)
        .then(response => resolve(response))
        .catch(error => reject(error))
    })
  },
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
}
