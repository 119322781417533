import { Role } from '@/auth/utils'

export default [
   // Trait Data
   {
    path: '/management/trait/trait-category',
    name: 'manage-trait-category',
    component: () => import('@/views/trait-manage/trait-datas/trait-categories/TraitCategoryList.vue'),
    meta: {
      breadcrumb: [
        {
          text: 'Trait Category',
        },
      ],
      authorize: [Role.Admin, Role.TraitsAndHealth],
    },
  },
  {
    path: '/management/trait/trait-category/:id',
    name: 'manage-trait-category-save',
    component: () => import('@/views/trait-manage/trait-datas/trait-categories/trait-categories-detail/SaveTraitCategory.vue'),
    meta: {
      breadcrumb: [
        {
          text: 'Trait Category',
          to: '/management/trait/trait-category',
        },
        {
          text: 'Save',
        },
      ],
      authorize: [Role.Admin, Role.TraitsAndHealth],
    },
  },
  
  {
    path: '/management/trait/trait-genotype',
    name: 'manage-trait-genotype',
    component: () => import('@/views/trait-manage/trait-datas/trait-genotypes/TraitGenotypeList.vue'),
    meta: {
      breadcrumb: [
        {
          text: 'Trait Genotype',
        },
      ],
      authorize: [Role.Admin, Role.TraitsAndHealth],
    },
  },
  {
    path: '/management/trait/trait-genotype/:id',
    name: 'manage-trait-genotype-save',
    component: () => import('@/views/trait-manage/trait-datas/trait-genotypes/trait-genotypes-detail/SaveTraitGenotype.vue'),
    meta: {
      breadcrumb: [
        {
          text: 'Trait Genotype',
          to: '/management/trait/trait-genotype',
        },
        {
          text: 'Save',
        },
      ],
      authorize: [Role.Admin, Role.TraitsAndHealth],
    },
  },

  {
    path: '/management/trait/trait-marker',
    name: 'manage-trait-marker',
    component: () => import('@/views/trait-manage/trait-datas/trait-markers/TraitMarkerList.vue'),
    meta: {
      breadcrumb: [
        {
          text: 'Trait Marker',
        },
      ],
      authorize: [Role.Admin, Role.TraitsAndHealth],
    },
  },
  {
    path: '/management/trait/trait-marker/:id',
    name: 'manage-trait-marker-save',
    component: () => import('@/views/trait-manage/trait-datas/trait-markers/trait-markers-detail/SaveTraitMarker.vue'),
    meta: {
      breadcrumb: [
        {
          text: 'Trait Marker',
          to: '/management/trait/trait-marker',
        },
        {
          text: 'Save',
        },
      ],
      authorize: [Role.Admin, Role.TraitsAndHealth],
    },
  },

  {
    path: '/management/trait/trait-feature',
    name: 'manage-trait-feature',
    component: () => import('@/views/trait-manage/trait-datas/trait-features/TraitFeatureList.vue'),
    meta: {
      breadcrumb: [
        {
          text: 'Trait Feature',
        },
      ],
      authorize: [Role.Admin, Role.TraitsAndHealth],
    },
  },
  {
    path: '/management/trait/trait-feature/:id',
    name: 'manage-trait-feature-save',
    component: () => import('@/views/trait-manage/trait-datas/trait-features/trait-features-detail/SaveTraitFeature.vue'),
    meta: {
      breadcrumb: [
        {
          text: 'Trait Feature',
          to: '/management/trait/trait-feature',
        },
        {
          text: 'Save',
        },
      ],
      authorize: [Role.Admin, Role.TraitsAndHealth],
    },
  },

  {
    path: '/management/trait/trait-effect',
    name: 'manage-trait-effect',
    component: () => import('@/views/trait-manage/trait-datas/trait-effects/TraitEffectList.vue'),
    meta: {
      breadcrumb: [
        {
          text: 'Trait Effect',
        },
      ],
      authorize: [Role.Admin, Role.TraitsAndHealth],
    },
  },
  {
    path: '/management/trait/trait-effect/:id',
    name: 'manage-trait-effect-save',
    component: () => import('@/views/trait-manage/trait-datas/trait-effects/trait-effects-detail/SaveTraitEffect.vue'),
    meta: {
      breadcrumb: [
        {
          text: 'Trait Effect',
          to: '/management/trait/trait-effect',
        },
        {
          text: 'Save',
        },
      ],
      authorize: [Role.Admin, Role.TraitsAndHealth],
    },
  },

  {
    path: '/management/trait/trait-population',
    name: 'manage-trait-population',
    component: () => import('@/views/trait-manage/trait-datas/trait-populations/TraitPopulationList.vue'),
    meta: {
      breadcrumb: [
        {
          text: 'Trait Population',
        },
      ],
      authorize: [Role.Admin, Role.TraitsAndHealth],
    },
  },
  {
    path: '/management/trait/trait-population/:id',
    name: 'manage-trait-population-save',
    component: () => import('@/views/trait-manage/trait-datas/trait-populations/trait-populations-detail/SaveTraitPopulation.vue'),
    meta: {
      breadcrumb: [
        {
          text: 'Trait Population',
          to: '/management/trait/trait-population',
        },
        {
          text: 'Save',
        },
      ],
      authorize: [Role.Admin, Role.TraitsAndHealth],
    },
  },

  // Trait
  {
    path: '/management/trait/traits',
    name: 'manage-traits',
    component: () => import('@/views/trait-manage/traits/TraitList.vue'),
    meta: {
      breadcrumb: [
        {
          text: 'Traits',
        },
      ],
      authorize: [Role.Admin, Role.TraitsAndHealth],
    },
  },
  {
    path: '/management/trait/traits/:featureId',
    name: 'manage-traits-save',
    component: () => import('@/views/trait-manage/traits/trait-detail/SaveTrait.vue'),
    meta: {
      breadcrumb: [
        {
          text: 'Traits',
          to: '/management/trait/traits',
        },
        {
          text: 'Save',
        },
      ],
      authorize: [Role.Admin, Role.TraitsAndHealth],
    },
  },

  {
    path: '/management/trait/frequency',
    name: 'manage-trait-frequency',
    component: () => import('@/views/trait-manage/trait-frequencies/TraitFrequencyList.vue'),
    meta: {
      breadcrumb: [
        {
          text: 'Trait Frequencies',
        },
      ],
      authorize: [Role.Admin, Role.TraitsAndHealth],
    },
  },
  {
    path: '/management/trait/frequency/:populationId',
    name: 'trait-frequencies-save',
    component: () => import('@/views/trait-manage/trait-frequencies/SaveTraitFrequencies.vue'),
    meta: {
      breadcrumb: [
        {
          text: 'Trait Frequencies',
          to: '/management/trait/frequency',
        },
        {
          text: 'Save',
        },
      ],
      authorize: [Role.Admin, Role.TraitsAndHealth],
    },
  },

  // Logs
  {
    path: '/management/trait/trait-logs',
    name: 'manage-trait-logs',
    component: () => import('@/views/trait-manage/trait-logs/TraitLogList.vue'),
    meta: {
      breadcrumb: [
        {
          text: 'Trait Logs',
        },
      ],
      authorize: [Role.Admin, Role.TraitsAndHealth],
    },
  },

]
