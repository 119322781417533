import ApiService from '@/common/api.service'

import { getField, updateField } from 'vuex-map-fields';

/* eslint-disable */
const getDefaultState = () => {
  return {
    presetMapForm: {
      id: 0,
      isActive: true,
      code: null,
      title: null,
      geoData: null,
      description: null,
      supplementaryInfo: null,
      referenceSamples: null,
      researchLinks: null,
      sourceCodes: null,
      pictureUrl: null,
      logoUrl: null,
      color: null,
    },
  }
}

const state = getDefaultState()

const getters = {
  getMapField(state) {
    return getField(state.presetMapForm);
  },
  getMapForm(state) {
    return state.presetMapForm;
  },
}

const mutations = { 
  updateMapField(state, field) {
    updateField(state.presetMapForm, field);
  },
  setMapField(state, data) {
    state.presetMapForm = data;
  },
  resetMapField(state) {
    Object.assign(state, getDefaultState())
  },
}

const actions = {
  fetchPresetMaps(ctx, queryParams) {
    return new Promise((resolve, reject) => {
      ApiService.post('PresetMapData/getall', queryParams, this)
        .then(response => resolve(response))
        .catch(error => reject(error))
    })
  },

  fetchPresetMap(context, queryParams) {
    return new Promise((resolve, reject) => {
      ApiService.get(`PresetMapData/get/${queryParams.id}`, queryParams, this)
        .then(response => resolve(response))
        .catch(error => reject(error))
    })
  },

  savePresetMap(ctx, queryParams) {
    return new Promise((resolve, reject) => {
      ApiService.post('PresetMapData/save', queryParams, this)
        .then(response => resolve(response))
        .catch(error => reject(error))
    })
  },

  removePresetMap(context, queryParams) {
    return new Promise((resolve, reject) => {
      ApiService.delete(`PresetMapData/${queryParams.id}`, {}, this)
        .then(response => resolve(response))
        .catch(error => reject(error))
    })
  },
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
}
