<template>
  <b-spinner  v-if="$store.getters.getLoader" small>
    </b-spinner>
</template>

<script>
export default {
  name: 'SpinnerLoader',
}
</script>
