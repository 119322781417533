export default {
    state: {
        loading: false,
        fileLoading: false,
    },
    getters: {
        getLoader: state => state.loading,
        getFileLoader: state => state.fileLoading,
    },
    mutations: {
        setLoader(state, value) {
            state.loading = value
        },
        setFileLoader(state, value) {
            state.fileLoading = value
        },
    },
}
