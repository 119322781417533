import ApiService from '@/common/api.service'

const state = {
  ticketData: null,
}

const getters = {
  getTicketData(state) {
    return state.ticketData;
  },
}

const mutations = {
  setTicketData(state, ticketData) {
    state.ticketData = ticketData;
  },
}

const actions = {
  fetchOpenTickets(context, queryParams) {
    return new Promise((resolve, reject) => {
      ApiService.post('Ticket/getallopen', queryParams, this)
        .then(response => resolve(response))
        .catch(error => reject(error))
    });
  },

  fetchClosedTickets(context, queryParams) {
    return new Promise((resolve, reject) => {
      ApiService.post('Ticket/getallclosed', queryParams, this)
        .then(response => resolve(response))
        .catch(error => reject(error))
    });
  },

  fetchTicket(context, queryParams) {
    return new Promise((resolve, reject) => {
      ApiService.get(`Ticket/${queryParams.ticketNo}`, {}, this)
        .then(response => {
          context.commit('setTicketData', response.result);
          resolve(response);
        })
        .catch(error => reject(error))
    });
  },

  closeTicket(context, queryParams) {
    return new Promise((resolve, reject) => {
      ApiService.get(`Ticket/close/${queryParams.ticketNo}`, queryParams, this)
        .then(response => resolve(response))
        .catch(error => reject(error))
    });
  },

  createTicketDetail(context, queryParams) {
    return new Promise((resolve, reject) => {
      ApiService.upload('ticket/create/message', queryParams, this)
        .then(response => resolve(response))
        .catch(error => reject(error))
    });
  },
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
}
