const AlertService = {
  success(instance, message, callback) {
    if (!message) {
      message = ''
    }
    
    instance.$swal({
      title: 'Successful.',
      text: message,
      icon: 'success',
      customClass: {
        confirmButton: 'btn btn-primary',
      },
      buttonsStyling: false,
    }).then(confirm => {
      if (typeof callback === 'function' && confirm) {
        callback()
      }
    })
  },

  error(instance, message, callback) {
    if (!message) {
      message = ''
    }
    
    instance.$swal({
      title: 'An error occurred!',
      text: message,
      icon: 'error',
      customClass: {
        confirmButton: 'btn btn-primary',
      },
      buttonsStyling: false,
    }).then(confirm => {
      if (typeof callback === 'function' && confirm) {
        callback()
      }
    })
  },

  warning(instance, message, callback) {
    if (!message) {
      message = ''
    }
    
    instance.$swal({
      title: 'Warning...!',
      text: message,
      icon: 'warning',
      customClass: {
        confirmButton: 'btn btn-primary',
      },
      buttonsStyling: false,
    }).then(confirm => {
      if (typeof callback === 'function' && confirm) {
        callback()
      }
    })
  },

  confirmDelete(instance, callback, message) {
    if (!message) {
      message = 'This action will delete the record permanently.'
    }

    instance.$swal({
      title: 'Are you sure?',
      text: message,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, Delete!',
      cancelButtonText: 'Cancel',
      customClass: {
        confirmButton: 'btn btn-primary',
        cancelButton: 'btn btn-outline-danger ml-1',
      },
      buttonsStyling: false,
    }).then(result => {
      if (typeof callback === 'function' && result.value) {
        callback()
      }
    })
  },

  confirmSuccess(instance, callback, message) {
    if (!message) {
      message = ''
    }

    instance.$swal({
      title: 'Confirm',
      text: message,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Ok, Continue',
      cancelButtonText: 'Cancel',
      customClass: {
        confirmButton: 'btn btn-primary',
        cancelButton: 'btn btn-outline-danger ml-1',
      },
      buttonsStyling: false,
    }).then(result => {
      if (typeof callback === 'function' && result.value) {
        callback()
      }
    })
  },
}

export default AlertService
