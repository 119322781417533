import ApiService from '@/common/api.service'

const state = {
  generalHomeReport: null,
  generalReportType: {
    text: 'Daily',
    value: 0,
  },
}

const getters = {
  getGeneralHomeReport(state) {
    return state.generalHomeReport
  },
}

const mutations = {
  setGeneralHomeReport(state, generalHomeReport) {
    state.generalHomeReport = generalHomeReport
  },
}

const actions = {
  fetchgGeneralHomeReport(ctx, queryParams) {
    return ApiService.get('main/homereport', queryParams, this)
      .then(response => {
        if (response.statusCode === 200) {
          ctx.commit('setGeneralHomeReport', response.result)
        }
      });
  },
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
}
