import { randomFromRange, subArray, addArray, getArraySum, squareArray, aggregateResults } from './utils'

/* eslint-disable */
const VuhadoService = {
    sourceObject: {
        sourceArray: [],
        targetArray: [],
        sourceNum: undefined,
        targetNum: undefined,
        dimensions: undefined,
        distMaxOut: 25,
        addGradient: false,
        gradientFrom: 0,
        gradientTo: 0.2,
        cyclesX: 1,
        cyclesXMulti: 1,
        addDistCol: 1,
        addDistColMulti: 1,
        addDistColRecal: 1,
        addDistColMultiRecal: true,
        addDistColMultiRecal: true,
        addDistColMultiRecal: true,
        addDistColMultiRecal: true,
        reduce: undefined,
        nPop: 0,
        addBarChart: 1,
        printZeroes: false,
        aggregate: true,
    },

    onSetSource(sourceArray) {
        this.sourceObject.sourceArray = sourceArray;
        this.sourceObject.sourceNum = sourceArray.length;
        this.sourceObject.dimensions = sourceArray[0].length - 1;
    },

    onSetTarget(targetArray) {
        this.sourceObject.targetArray = targetArray;
        this.sourceObject.targetNum = targetArray.length;
    },

    textareaToArray(textareaData, textareaId) {
        const response = {
            isSuccess: true,
            message: '',
            result: [],
        };

        let i, j, m, n, text1, text2, diff12,
            text3, diff23, text4, diff34, lines, columnNum;

        textareaId = textareaId.toUpperCase();
        text1 = textareaData.trim().replace(/\r\n/g, "\n").replace(/\"/g, "").replace(/\</g, "&lt;").replace(/\>/g, "&gt;");
        text2 = text1.replace(/[^\S\n]/g, "");
        diff12 = text1.length - text2.length;
        if (diff12 > 0) {
            response.message += "WARNING! Number of white-space characters removed in " + textareaId + " data: " + diff12 + ". ";
        }
        text3 = text2.replace(/\n+/g, "\n");
        diff23 = text2.length - text3.length;
        if (diff23 > 0) {
            response.message += "WARNING! Number of empty lines removed in " + textareaId + " data: " + diff23 + ". ";
        }
        text4 = text3.replace(/\,+/g, "\,");
        diff34 = text3.length - text4.length;
        if (diff34 > 0) {
            response.message += "ERROR! Number of missing values in " + textareaId + " data: " + diff34 + ". ";
            response.isSuccess = false;
            return;
        }
        lines = text4.split("\n");
        columnNum = lines[0].split(",").length;
        if (columnNum === 1) {
            response.message += "ERROR! Data load error in " + textareaId + ". ";
            response.isSuccess = false;
            return;
        }
        for (i = 0, n = lines.length; i < n; i++) {
            lines[i] = lines[i].split(",");
            if (lines[i].length !== columnNum) {
                response.message += "ERROR! Variable column number in " + textareaId + " data. ";
                response.isSuccess = false;
                return;
            }
            for (j = 1, m = lines[i].length; j < m; j++) {
                if (isNaN(lines[i][j])) {
                    response.message += "ERROR! Non-numerical value detected in " + textareaId + " data. ";
                    response.isSuccess = false;
                    return;
                }
            }
        }

        for (i = 0, n = lines.length; i < n; i++) {
            for (j = 1; j < columnNum; j++) {
                lines[i][j] = Number(lines[i][j]);
            }
        }

        response.result = lines;
        return response;
    },

    distances(targetId) {
        
        let i, output = [], resultsNum, getDistance, distanceCurrent,
            gradStyle1 = "", gradStyle2 = "", gradHSL = "",
            distMaxOut = this.sourceObject.distMaxOut;

        const target = this.sourceObject.targetArray[targetId].slice(),
            distances = Array(this.sourceObject.sourceNum).fill([]),
            gradFrom = this.sourceObject.gradientFrom,
            gradTo = this.sourceObject.gradientTo;
        for (i = 0; i < this.sourceObject.sourceNum; i++) {
            getDistance = subArray(target, this.sourceObject.sourceArray[i]);
            getDistance.shift();
            getDistance = squareArray(getDistance);
            getDistance = getArraySum(getDistance);
            getDistance = Math.sqrt(getDistance);
            distances[i] = distances[i].concat(this.sourceObject.sourceArray[i][0]);
            distances[i].push(getDistance);
        }

        distances.sort(function (a, b) {
            return a[1] - b[1];
        });
        resultsNum = this.sourceObject.sourceNum;
        if (target[0] === distances[0][0]) {
            distances.shift();
            resultsNum--;
        }
        if (resultsNum < distMaxOut) {
            distMaxOut = resultsNum;
        }
        if (this.sourceObject.addGradient) {
            gradStyle1 = ' style="color:black;background-color:hsl(';
            gradStyle2 = ', 100%, 50%)"';
        }
        // output.push({ gradHSL, distance: 0, title: target[0] });
        for (i = 0; i < distMaxOut; i++) {
            distanceCurrent = distances[i][1];
            if (this.sourceObject.addGradient) {
                if (distanceCurrent < gradFrom) {
                    gradHSL = 120;
                } else if (distanceCurrent > gradTo) {
                    gradHSL = 240;
                } else {
                    gradHSL = 120 - (((distanceCurrent - gradFrom) / (gradTo - gradFrom)) * 240);
                }
            }
            output.push({ gradHSL, distance: distanceCurrent.toFixed(8), title: distances[i][0] });
        }

        return output;
    },

    singleFMC(targetId) {
        
        let i, n, currentResult, sourceNumLocal = this.sourceObject.sourceNum,
            resultsTable, time = Date.now(), result;
        const slots = 500,
            target = this.prepareTarget(targetId, slots),
            source = this.prepareSource(slots),
            addDC = (this.sourceObject.addDistCol == 1 ? false : this.sourceObject.addDistCol);
        if (this.sourceObject.nPop == 0) {
            result = this.fastMonteCarlo(target, source, targetId, slots, this.sourceObject.cyclesX, addDC, this.sourceObject.addDistColRecal, this.sourceObject.sourceNum);
            resultsTable = Array(sourceNumLocal)
            for (i = 0; i < sourceNumLocal; i++) {
                resultsTable[i] = Array(2);
                resultsTable[i][0] = this.sourceObject.sourceArray[i][0];
                resultsTable[i][1] = result.scores[i];
            }
        } else {
            result = this.nPops(target, source, targetId, slots, this.sourceObject.cyclesX, this.sourceObject.addDC, this.sourceObject.addDistColRecal, this.sourceObject.nPop);
            sourceNumLocal = result.scores.length;
            resultsTable = Array(sourceNumLocal);
            for (i = 0; i < sourceNumLocal; i++) {
                resultsTable[i] = Array(2);
                resultsTable[i][0] = result.names[i];
                resultsTable[i][1] = result.scores[i];
            }
        }
        if (this.sourceObject.aggregate) {
            resultsTable = aggregateResults(resultsTable, sourceNumLocal);
        }
        resultsTable.sort(function (a, b) {
            return b[1] - a[1];
        });
        time = Date.now() - time;

        const response = {
            sourceObject: {
                addBarChart: this.sourceObject.addBarChart,
                target: this.sourceObject.targetArray[targetId][0],
                nPop: this.sourceObject.nPop,
                addDistCol: this.sourceObject.addDistCol,
                addDistColRecal: this.sourceObject.addDistColRecal,
                cyclesX: this.sourceObject.cyclesX,
            },
            result: result,
            sourceNumLocal: sourceNumLocal,
            time: time,
            outPuts: [],
        };

        response.titleOutPut = "<th colspan='" + (this.sourceObject.addBarChart == 2 ? 3 : 2) + "' class='singleheader'>Target: " + this.sourceObject.targetArray[targetId][0] + "<br/>";
        response.titleOutPut += "Distance: " + (100 * result.distance).toFixed(4) + "% / " + result.distance.toFixed(8) + (this.sourceObject.nPop == 0 ? "" : " | R" + this.sourceObject.nPop + "P") + (this.sourceObject.addDistCol == 1 ? "" : " | ADC: " + this.sourceObject.addDistCol / 8 + "x" + (this.sourceObject.addDistColRecal ? " RC" : "")) + "<br/>";
        response.titleOutPut += '<div class="singleinfo nonselectable" data-nonselectable="' + (this.sourceObject.nPop == 0 ? 'Sources: ' + sourceNumLocal + ' | Cycles: ' + Math.ceil(sourceNumLocal * this.sourceObject.cyclesX / 4) : 'Populations: ' + result.pops + ' | Iterations: ' + result.iter) + ' | Time: ' + time / 1000 + '&nbsp;s' + '"></div>';
        response.titleOutPut += "</th>";
        for (i = 0, n = resultsTable.length; i < n; i++) {
            if (this.sourceObject.printZeroes || resultsTable[i][1] != 0) {
                response.outPuts.push({
                    currentResult: resultsTable[i][1] * 100,
                    resultsTable: resultsTable[i][0],
                });
            }
        }

        return response;
    },

    prepareTarget(targetId, slots) {
        let i;
        const target = this.sourceObject.targetArray[targetId].slice();
        target.shift();
        for (i = 0; i < this.sourceObject.dimensions; i++) {
            target[i] = target[i] / slots;
        }
        return target;
    },

    prepareSource(slots) {
        let i, j, tempLine;
        const source = Array(this.sourceObject.sourceNum);
        for (i = 0; i < this.sourceObject.sourceNum; i++) {
            tempLine = this.sourceObject.sourceArray[i].slice();
            tempLine.shift();
            source[i] = tempLine.slice();
            for (j = 0; j < this.sourceObject.dimensions; j++) {
                source[i][j] = source[i][j] / slots;
            }
        }
        return source;
    },

    fastMonteCarlo(target, source, targetId, slots, cyclesMultiplier, distColMultiplier, recalculate, sourceNum) {
        let i, j, tempLine, currentSlots, currentPoint, currentDistance, nextSlots, ranking = Array(),
            nextPoint, nextDistance, previousDistance, rankingNum, dimNum = this.sourceObject.dimensions;
        const cycles = Math.ceil(sourceNum * cyclesMultiplier / 4), scores = Array(sourceNum).fill(0),
            result = { target: targetId, distance, scores },
            bigNumber = 100000000000000000;
        if (distColMultiplier) {
            distColMultiplier /= 8;
            dimNum++;
            for (i = 0; i < sourceNum; i++) {
                source[i] = subArray(source[i], target);
                source[i].push(distColMultiplier * Math.sqrt(distance(source[i])));
            }
        }
        else {
            for (i = 0; i < sourceNum; i++) {
                source[i] = subArray(source[i], target);
            }
        }

        function randomizedSlots(oldSlots) {
            let i, newSlots = Array(slots);
            for (i = 0; i < slots; i++) {
                newSlots[i] = randomFromRange(0, sourceNum);
                while (newSlots[i] == oldSlots[i]) {
                    newSlots[i] = randomFromRange(0, sourceNum);
                }
            }
            return newSlots;
        }

        function buildPoint(fromSlots) {
            let i, tempLine, newPoint = Array(dimNum).fill(0);
            for (i = 0; i < slots; i++) {
                tempLine = source[fromSlots[i]].slice();
                newPoint = addArray(newPoint, tempLine);
            }
            return newPoint;
        }

        function distance(fromPoint) {
            let dist = squareArray(fromPoint);
            dist = getArraySum(dist);
            return dist;
        }

        if (sourceNum == 1) {
            currentSlots = Array(slots).fill(0);
            currentPoint = buildPoint(currentSlots);
            currentDistance = distance(currentPoint);
            scores[0] = 1;
            result.distance = Number(Math.sqrt(currentDistance).toFixed(8));
            result.scores = scores;
            return result;
        }
        currentSlots = Array(slots).fill(-1);
        currentSlots = randomizedSlots(currentSlots);
        currentPoint = buildPoint(currentSlots);
        currentDistance = distance(currentPoint);
        for (i = 0; i < cycles; i++) {
            nextSlots = randomizedSlots(currentSlots);
            for (j = 0; j < slots; j++) {
                nextPoint = subArray(currentPoint, source[currentSlots[j]]);
                nextPoint = addArray(nextPoint, source[nextSlots[j]]);
                nextDistance = distance(nextPoint);
                if (nextDistance < currentDistance) {
                    currentSlots[j] = nextSlots[j];
                    currentPoint = nextPoint;
                    currentDistance = nextDistance;
                }
            }
        }
        for (i = 0; i < slots; i++) {
            scores[currentSlots[i]] += 1;
        }
        for (i = 0; i < sourceNum; i++) {
            if (scores[i] > 0) {
                ranking.push([i, scores[i]]);
            }
        }
        ranking.sort(function (a, b) {
            return b[1] - a[1];
        });
        rankingNum = ranking.length;
        function secondStage() {
            currentDistance = Math.round(bigNumber * currentDistance);
            do {
                previousDistance = currentDistance;
                for (i = rankingNum - 1; i > -1; i--) {
                    if (ranking[i][1] > 0) {
                        for (j = 0; j < rankingNum; j++) {
                            if (i == j) { continue; }
                            nextPoint = subArray(currentPoint, source[ranking[i][0]]);
                            nextPoint = addArray(nextPoint, source[ranking[j][0]]);
                            nextDistance = Math.round(bigNumber * distance(nextPoint));
                            if (nextDistance < currentDistance) {
                                ranking[i][1]--;
                                ranking[j][1]++;
                                currentPoint = nextPoint;
                                currentDistance = nextDistance;
                                break;
                            }
                        }
                    }
                }
            }
            while (currentDistance < previousDistance);
        }
        secondStage();
        for (i = 0; i < rankingNum; i++) {
            scores[ranking[i][0]] = ranking[i][1];
        }
        if (distColMultiplier && recalculate) {
            dimNum--;
            currentPoint.pop();
            currentDistance = distance(currentPoint);
            for (i = 0; i < sourceNum; i++) {
                source[i].pop();
            }
            ranking = [];
            for (i = 0; i < sourceNum; i++) {
                if (scores[i] > 0) {
                    ranking.push([i, scores[i]]);
                }
            }
            ranking.sort(function (a, b) {
                return b[1] - a[1];
            });
            rankingNum = ranking.length;
            secondStage();
            for (i = 0; i < rankingNum; i++) {
                scores[ranking[i][0]] = ranking[i][1];
            }
        }

        for (i = 0; i < sourceNum; i++) {
            scores[i] = scores[i] / slots;
        }
        if (distColMultiplier && !recalculate) { currentPoint.pop(); }
        currentDistance = distance(currentPoint);
        result.distance = Number(Math.sqrt(currentDistance).toFixed(8));
        result.scores = scores;
        return result;
    },

    nPops(target, source, targetId, slots, cyclesMultiplier, distColMultiplier, recalculate, nPop) {
        let namesArr = [], idArr = [], initSet = [], initResult, initResultsTable = [], counter = 0, sloths = slots,
            initSourceNum, popNum, currentSet = [], currentResult, nextSet, nextResult, newSource = [], newNamesArr = [];

        function aggregateArray(arr) {
            let sortedArr = arr.slice(), aggregatedArr = [];
            sortedArr.sort(function (a, b) {
                return a[0].localeCompare(b[0]);
            });
            for (let name = null, i = 0, j = -1, n = sortedArr.length; i < n; i++) {
                if (sortedArr[i][0] != name) {
                    j++;
                    name = sortedArr[i][0];
                    aggregatedArr.push([]);
                }
                aggregatedArr[j].push(sortedArr[i]);
            }
            return aggregatedArr;
        }

        function runFMC(setToRun) {
            counter++;
            let currentSource = [];
            for (item of setToRun) {
                currentSource = currentSource.concat(source[item]);
            }
            return fastMonteCarlo(target, currentSource, targetId, slots, cyclesMultiplier, distColMultiplier, recalculate, currentSource.length);
        }

        function runFMCadc(setToRun, slots, adc, adcmltp, cmltp) {
            let currentSource = [];
            for (item of setToRun) {
                currentSource = currentSource.concat(source[item]);
            }
            return fastMonteCarlo(target, currentSource, targetId, slots, cmltp, adcmltp, adc, currentSource.length);
        }

        function getNames(setToRun) {
            let names = [];
            for (item of setToRun) {
                names = names.concat(namesArr[item]);
            }
            return names;
        }

        function newPop(currentSetItem) {
            let newPop = randomFromRange(0, popNum);
            while (newPop == currentSetItem || currentSet.includes(newPop)) {
                newPop = randomFromRange(0, popNum);
            }
            return newPop;
        }

        for (let i = 0, tempArr; i < sourceNum; i++) {
            tempArr = [sourceArray[i][0].split(':').shift(), sourceArray[i][0]];
            source[i] = tempArr.concat(source[i]);
        }
        source = aggregateArray(source);
        for (let item in source) {
            namesArr.push([]);
            for (let item2 in source[item]) {
                source[item][item2].shift();
                namesArr[item].push(source[item][item2].shift());
                idArr.push(item);
            }
        }
        popNum = source.length;
        for (let i = 0; i < popNum; i++) {
            initSet.push(i);
        }
        let slotNum = 50, cyclesNum = 5;
        initResult = [
            runFMCadc(initSet, slotNum, true, 0.5, cyclesNum),
            runFMCadc(initSet, slotNum, false, 0, cyclesNum),
            runFMCadc(initSet, slotNum, true, 1, cyclesNum),
            runFMCadc(initSet, slotNum, false, 0, cyclesNum),
            runFMCadc(initSet, slotNum, true, 2, cyclesNum),
            runFMCadc(initSet, 500, false, 0, 2)
        ];
        for (let item in idArr) {
            for (let item2 in initResult) {
                initResultsTable.push([idArr[item], initResult[item2].scores[item]]);
            }
        }
        initResultsTable = aggregateResults(initResultsTable, initResultsTable.length);
        initResultsTable.sort(function (a, b) {
            return b[1] - a[1];
        });
        for (let item in initResultsTable) {
            if (Number(initResultsTable[item][1]) > 0.02) {
                newSource.push(source[Number(initResultsTable[item][0])]);
                newNamesArr.push(namesArr[Number(initResultsTable[item][0])]);
            } else {
                break;
            }
        }
        source = newSource;
        namesArr = newNamesArr;
        popNum = source.length;
        if (popNum <= nPop) {
            for (let i = 0; i < popNum; i++) {
                currentSet.push(i);
            }
            return finishIt();
        } else {
            for (let i = 0; i < nPop; i++) {
                currentSet.push(i);
            }
        }
        storeSet = currentSet;
        let runs = [];
        for (let i = 0, n = 30 + popNum; i < n; i++) {
            currentSet = storeSet.slice();
            currentResult = runFMC(currentSet);
            slots = 35;
            for (let i = 0, n = Math.ceil(popNum); i < n; i++) {
                for (let j = 0; j < nPop; j++) {
                    nextSet = currentSet.slice();
                    nextSet[j] = newPop(nextSet[j]);
                    nextResult = runFMC(nextSet);
                    if (nextResult.distance < currentResult.distance) {
                        currentResult = nextResult;
                        currentSet = nextSet;
                    }
                }
            }
            runs.push([currentResult.distance, currentSet.slice()]);
        }
        runs.sort(function (a, b) {
            return a[0] - b[0];
        });
        currentSet = runs[0][1];

        function finishIt() {
            slots = sloths;
            currentResult = runFMC(currentSet);
            currentResult.names = getNames(currentSet);
            currentResult.pops = popNum;
            currentResult.iter = counter;
            return currentResult;
        }
        return finishIt();
    },
}

export default VuhadoService
