import ApiService from '@/common/api.service'
import { getCurrentDate, apiToDate } from '@core/utils/filter'
import { getField, updateField } from 'vuex-map-fields';

/* eslint-disable */
const getDefaultState = () => {
  return {
    dataUpdateForm: {
      id: 0,
      isActive: true,
      title: null,
      content: null,
      dtDate: getCurrentDate(),
    },
  }
}

const state = getDefaultState()

const getters = {
  getDataUpdateField(state) {
    return getField(state.dataUpdateForm);
  },
  getDataUpdateForm(state) {
    return state.dataUpdateForm;
  },
}

const mutations = { 
  updateDataUpdateField(state, field) {
    updateField(state.dataUpdateForm, field);
  },
  setDataUpdateField(state, data) {
    data.dtDate = apiToDate(data.dtDate);
    state.dataUpdateForm = data;
  },
  resetDataUpdateField(state) {
    Object.assign(state, getDefaultState())
  },
}

const actions = {
  fetchDataUpdates(ctx, queryParams) {
    return new Promise((resolve, reject) => {
      ApiService.post('DataUpdate/getall', queryParams, this)
        .then(response => resolve(response))
        .catch(error => reject(error))
    })
  },

  fetchDataUpdate(context, queryParams) {
    return new Promise((resolve, reject) => {
      ApiService.get(`DataUpdate/get/${queryParams.id}`, {}, this)
        .then(response => resolve(response))
        .catch(error => reject(error))
    })
  },

  saveDataUpdate(ctx, queryParams) {
    return new Promise((resolve, reject) => {
      ApiService.post('DataUpdate/save', queryParams, this)
        .then(response => resolve(response))
        .catch(error => reject(error))
    })
  },

  removeDataUpdate(context, queryParams) {
    return new Promise((resolve, reject) => {
      ApiService.delete(`DataUpdate/${queryParams.id}`, {}, this)
        .then(response => resolve(response))
        .catch(error => reject(error))
    })
  },
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
}
