/* eslint-disable */
export const randomFromRange = (min, max) => {
  return Math.floor(Math.random() * (max - min) ) + min;
}

export const subArray = (arr1, arr2) => {
  const subtracted = arr1.map(function (elmnt, index) {
    return elmnt - arr2[index];
  });
  return subtracted;
}


export const addArray = (arr1, arr2) => {
  const added = arr1.map(function (elmnt, index) {
    return elmnt + arr2[index];
  });
  return added;
}


export const getArraySum = (arr) => {
  function arrSum (total, num) {
    return total + num;
  }
  return arr.reduce(arrSum);
}

export const squareArray = (arr) => {
  const squared = arr.map(function (elmnt) {
    return elmnt * elmnt;
  });
  return squared;
}

export const aggregateResults = (resultsTable, sourceNumLocal) => {
  let i, popName, storedName;
  for (i = 0; i < sourceNumLocal; i++) {
    popName = resultsTable[i][0].split(":");
    resultsTable[i][0] = popName[0];
  }
  resultsTable.sort( function(a, b) {
    return a[0].localeCompare(b[0]);
  });
  for (i = sourceNumLocal - 2; i > -1; i--) {
    if (resultsTable[i][0] == resultsTable[i + 1][0]) {
      storedName = resultsTable[i][0];
      resultsTable[i] = addArray(resultsTable[i],resultsTable[i + 1]);
      resultsTable[i][0] = storedName;
      resultsTable.splice(i + 1, 1);
    }
  }
  return resultsTable;
}
